import { useState } from 'react';
import { Link } from "react-router-dom";

import("./ContactComponent.css");


export default function ContactComponent() {

    const [completedEmailContact, setcompleteEmailContactState] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);


    let contactForm =
        <form onSubmit={enviarDatos} id="contactForm" className="form">
            <div id="contact" className='formContainer'>
            <div className='leftSide'>
                <div className="question">
                    <input placeholder='Name' required type="text"></input>
                </div>
                <div className="question">
                    <input placeholder='Phone' required minLength="6" type="text"></input>
                </div>
                <div className="question">
                    <input placeholder='Email' required type="email"></input>
                </div>
                <div name="service" className="question serviceType">
                    <select required>
                        <option value="Quick cleaning">Commercial Cleaning</option>
                        <option value="Normal cleaning">House Cleaning</option>
                        <option value="Deep cleaning">Deep Cleaning</option>
                    </select>
                </div>
            </div>
            <div className='rightSide'>
                <div className="question">
                    <textarea placeholder='Description' minLength="15" required rows="4" cols="20">
                    </textarea>
                </div>
                <div className="question auth">
                <label className='center'>Contact me via WhatsApp</label>

                <input id="auth" defaultChecked type='checkbox'/>

                </div>
                <div className="submitBottom">
                    <button type='submit' value="Submit"><span>Send</span></button>
                </div>
            </div>
            </div>
        </form>
        ;

    function setFormControlsDisabledState(formControls, disabled = true) {
        if (disabled) {
            formControls.forEach((formControl) => {
                formControl.setAttribute('disabled', disabled);
            });
        } else {
            formControls.forEach((formControl) => {
                formControl.removeAttribute('disabled');
            });
        }
    }

    function enviarDatos(event) {
        event.preventDefault();
        var form = document.getElementById("contactForm");
        let nameInput = form[0];
        let emailInput = form[1];
        let phoneInput = form[2];
        let selectedService = form[3];
        let descriptionInput = form[4];
        let authInput = form[5];
        setFormControlsDisabledState([nameInput, emailInput, phoneInput, descriptionInput,selectedService, authInput, event.target], true);
        let name = (nameInput.validity.valid && nameInput.value) || "The name was not provided";
        let email = (emailInput.validity.valid && emailInput.value) || "No email provided";
        let phone = (phoneInput.validity.valid && phoneInput.value) || "No phone provided";
        let service = (selectedService.validity.valid && selectedService.value) || "No selected service";
        let authOption = authInput.value ? 'Allowed' : 'Not Allowed';
        let description = (descriptionInput.validity.valid && descriptionInput.value) || "No description provided";
        if (description !== "") {
            fetch("https://beakmovilservices-claudia-production.up.railway.app/contacto", {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({ host: "Página Web", name, email, phone, description, service, authOption})
            }).then((resolve) => {
                if (resolve.status === 200) {
                    setcompleteEmailContactState(true);
                }
            }).catch(() => {
                setFormControlsDisabledState([nameInput, emailInput, phoneInput, descriptionInput, authInput, event.target], false);
                setShowErrorMessage(true);
            });
        }
    }

    return (
        <div className="main">
            <div className="container">
                <section className='titleSection'>
                    <div id="contact" className="formTitle">
                        <h3>Contact us</h3>
                    </div>
                    <div className='contactDescription'>
                        <p>We will contact you soon as possible to give you a budget
                        </p>
                    </div>
                </section>
                <section className="contactForm">
                    {
                        function () {
                            if (completedEmailContact) {
                                return (<div className="completedMessage"><h2>Thank you, we contact you soon as possible !.</h2></div>);
                            }
                            else if (showErrorMessage) {
                                return (<><h2>An error ocurrs, please contact us via WhatsApp or retry leter.</h2></>);
                            } else {
                                return (contactForm);
                            }
                        }()
                    }
                </section>
            </div>
        </div>
    );
}
