import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from 'react-responsive';

import("./ServicesComponent.css");
import("../../index.css");


export default function ServicesComponent() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
          slidesToSlide: 0// optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 1023, min: 0 },
          items: 1,
          slidesToSlide: 0 // optional, default to 1.
        }
      };

    return (
        <div className="fullContainerServices">
            <div className="container servicesContainer">
                <a id="offers"></a>
                <div className="sectionTitle">
                    <h3>Offers</h3>
                </div>
                <div className='servicesCarousel'>
                    <Carousel
                    centerMode={false}
                       swipeable={false}
                       draggable={false}
                       showDots={true}
                       responsive={responsive}
                       infinite={false}
                       keyBoardControl={true}
                       customTransition="all .5"
                       transitionDuration={500}
                       dotListClass="custom-dot-list-style"
                       itemClass="carousel-item"
                       >
                                <div className='carousel-item center'>
                            <img src={ isMobile ? "https://beakmovil.sirv.com/dirtydiditdone/offer_mobile.jpg": "https://beakmovil.sirv.com/dirtydiditdone/2024_offer_1.webp"} />
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    );
}
