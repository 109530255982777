import React from 'react';
import Carousel from 'react-multi-carousel';

import("./ClientsComponent.css");

export default function ClientsComponent() {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
          slidesToSlide: 1// optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 1023, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

    return (
        <div className="sectionContainer fullContainerClients">
            <div className="container">
                <div id="services" className="sectionTitle">
                    <h3>Services</h3>
                </div>
                <div className='servicesCards'>
                    <div className='service'>
                        <div className='previewImage'>
                            <img height="100%" width="100%" src='https://beakmovil.sirv.com/dirtydiditdone/normal.webp'/>
                        </div>
                        <div className='serviceFooter'>
                            <div className='serviceTitle'>
                                <h5>Commercial cleaning</h5>
                            </div>
                            <div className='serviceDescription'>
                             <p>Special for offices, waiting room, hallways including floor and carpet cleaning, removing dust and surface stains from office furniture.</p>
                            </div>
                        </div>
                    </div>

                    <div className='service'>
                        <div className='previewImage'>
                            <img width="100%" src='https://beakmovil.sirv.com/dirtydiditdone/quick.webp'/>
                        </div>
                        <div className='serviceFooter'>
                            <div className='serviceTitle'>
                                <h5>House cleaning</h5>
                            </div>
                            <div className='serviceDescription'>
                             <p>Vacuum, mop, and remove dust from principal areas of your home like bathrooms, bedrooms, livingroom and kitchen.</p>
                            </div>
                        </div>
                    </div>

                    <div className='service'>
                        <div className='previewImage'>
                            <img width="100%" src='https://beakmovil.sirv.com/dirtydiditdone/deep.webp'/>
                        </div>
                        <div className='serviceFooter'>
                            <div className='serviceTitle'>
                                <h5>Deep cleaning</h5>
                            </div>
                            <div className='serviceDescription'>
                             <p>Removing dust, removing build up, cleaning hard-to reach surfaces, new tenant rental.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="servicesMobileCarousel">
                <Carousel
                       renderButtonGroupOutside={true}
                       swipeable={false}
                       draggable={false}
                       showDots={true}
                       responsive={responsive}
                       infinite={true}
                       keyBoardControl={true}
                       customTransition="all .5"
                       transitionDuration={500}
                       dotListClass="custom-dot-list-style"
                       itemClass="carousel-item"
                       >
                    <div className='service'>
                        <div className='previewImage'>
                            <img height="100%" width="100%" src='https://beakmovil.sirv.com/dirtydiditdone/normal_.svg'/>
                        </div>
                        <div className='serviceFooter'>
                            <div className='serviceTitle'>
                                <h5>Commercial cleaning</h5>
                            </div>
                            <div className='serviceDescription'>
                             <p>Special for offices, waiting room, hallways including floor and carpet cleaning, removing dust and surface stains from office furniture.</p>
                            </div>
                        </div>
                    </div>

                    <div className='service'>
                        <div className='previewImage'>
                            <img width="100%" src='https://beakmovil.sirv.com/dirtydiditdone/quick.svg'/>
                        </div>
                        <div className='serviceFooter'>
                            <div className='serviceTitle'>
                                <h5>House cleaning</h5>
                            </div>
                            <div className='serviceDescription'>
                             <p>Vacuum, mop, and remove dust from principal areas of your home like bathrooms, bedrooms, livingroom and kitchen.</p>
                            </div>
                        </div>
                    </div>

                    <div className='service'>
                        <div className='previewImage'>
                            <img width="100%" src='https://beakmovil.sirv.com/dirtydiditdone/deep.svg'/>
                        </div>
                        <div className='serviceFooter'>
                            <div className='serviceTitle'>
                                <h5>Deep cleaning</h5>
                            </div>
                            <div className='serviceDescription'>
                             <p>Removing dust, removing build up, cleaning hard-to reach surfaces, new tenant rental.</p>
                            </div>
                        </div>
                    </div>
                    </Carousel>
                </div>

                </div>
        </div>
    );
}
