import("./FooterComponent.css");

export default function FooterComponent() {
    return (
        <div className="fullContainerFooter">
        <div className="container">
            <section className="sectionFooter leftSide">
                <div className='contactData'>
                    <img width="48" src="logos/geo.png" alt="FacebookLogo"/><a target="_blank" href="https://maps.app.goo.gl/DLkNkhDwC8FYTjGm8">Los Angeles, California</a>
                </div>
                <div className='contactData'>
                    <img width="48" src="logos/facebook2.png" alt="FacebookLogo"/><a>Facebook page</a>
                </div>
                <div className='contactData'>
                    <img width="48" src="logos/whatsapp_logo.png" alt="WhatsApp logo"/><a href="https://wa.me/18058152574?text=I'm%20interested%20in%20your%20services%20">(805)815-2574</a>
                </div>
            </section>
            <section className="sectionFooter middleSide">

            </section>

        </div>
    </div>
    );
}
