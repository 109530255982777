import './TopBannerComponent.css';


export default function TopBannerComponent() {

    function mobileMenuActive() {
        let button = document.getElementById('mobile_menu_element');
        let mobileMenu = document.getElementById('mobileBlockElement');
        if ([...button.classList].indexOf('activo') !== -1 && [...mobileMenu.classList].indexOf('activo') !== -1) {
            button.classList.value = "mobileMenu center";
            mobileMenu.classList.value = "mobileBlock";
        } else {
            button.classList.add('activo');
            mobileMenu.classList.add('activo');
        }        
    }

    return (
        <section className="topBannerContainer">
            <div className="bannerInfo">
                <div className="innerBannerInfo">
                    <div className="leftData">
                        <a href="/">
                            <img width="60" className="mainLogo" alt="Logo" src="https://beakmovil.sirv.com/dirtydiditdone/logo.svg"/>
                        </a>
                        <div className="menuElement">
                            <a href='#services'>Services</a>
                        </div>
                        <div className="menuElement">
                            <a href='#offers'>Offers</a>
                        </div>
                        <div className="menuElement">
                            <a href='#about'>About us</a>
                        </div>
                        <div className="menuElement">
                            <a href="#contact" className='link'>Contact us</a>
                        </div>
                        <div className='mobileMenu center'>
                            <button id="mobile_menu_element"  onClick={mobileMenuActive}></button>
                        </div>
                    </div>
                    <div className="rightData">
                        <a className='phone' target="_blank" rel="noreferrer" href="https://wa.me/18058152574?text=I'm%20interested%20in%20your%20services%20">(805) 815-2574
                            <img src="whats_ico.png"></img>
                        </a>
                    </div>
                </div>
                <div id="mobileBlockElement" className='mobileBlock'>
                    <div className='mobileBlock-element'>
                        <a onClick={mobileMenuActive} href='#offers'>Offers</a>
                    </div>
                    <div className='mobileBlock-element'>
                        <a onClick={mobileMenuActive} href='#services'>Services</a>
                    </div>
                    <div className='mobileBlock-element'>
                        <a onClick={mobileMenuActive} href='#about'>About Us</a>
                    </div>
                    <div className='mobileBlock-element'>
                        <a onClick={mobileMenuActive} href='#contact'>Contact Us</a>
                    </div>
                </div>
            </div>
            <div className="videoContainer">
            <video muted loop autoPlay  src="https://beakmovil.sirv.com/dirtydiditdone/banner.mp4"></video>
                <section className="mainContactContainer">
                    <h1>Dirty Did it Done</h1>
                    <h2>Enjoy your spaces, we take care of the cleaning</h2>
                    <button onClick={console.log('aquiesto')} className="mainContact">
                        <a href='#contact'>Cotization</a>
                    </button>
                </section>
            </div>
        </section>
    );

} 