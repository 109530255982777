import React from 'react';
import { useMediaQuery } from 'react-responsive';

import("./AboutUsComponent.css");

export default function AboutUsComponent() {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return (
        <div id="about" className='sectionContainer fullContainerAbout'>
            <div className='container'>
                <div className="sectionTitle">
                    <h3>About us</h3>
                    <div>
                        {isMobile ? <img src="https://beakmovil.sirv.com/dirtydiditdone/about_us_mobile.svg" /> : <img src="https://beakmovil.sirv.com/dirtydiditdone/about_us_web.svg"  />}
                    </div>
                </div>
                <div className='aboutUsData'>
                    <div className='aboutUsDataImageContainer'>
                        {isMobile ? <img src="https://beakmovil.sirv.com/dirtydiditdone/about_us_web.svg"/>: ""}
                    </div>
                    <div className="data">
                        <div className='over'>
                            <h5>Dirty Did it Done</h5>
                            <p>We offer professional cleaning services for your business or home, always thinking about the comfort and needs of our clients, providing a specialized service so that you can enjoy your spaces and stop worrying about cleaning.</p>
                        </div>
                        <div className='location'>
                            <button>
                                <img width="43" src='logos/geo.png' />
                                {isMobile ? <div className="line"></div> : <span>|</span>}
                                <span> Los Angeles, California</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}